import '../styles/contact-us-form.css';
import '../styles/menu.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import call1 from '../images/call-24-px.png';
import droparrow from '../images/DownArrow2.svg';
import email from '../images/email.svg';
import email1 from '../images/ic-email-24-px.png';
import iconback from '../images/icon-back-black.svg';
import iconorange from '../images/icon-back-orange.svg';
import loginbtn from '../images/locklogin.svg';
import phone from '../images/phone.svg';
import * as commonStyles from '../styles/head.module.css';
import AboutContent from './aboutHeader';
import HubSpotContactUsForm from './hubSpotContactUsForm';
import InsuranceCards from './InsuranceLinks';
import InsurancePartners from './InsurancePartners';

const HDImg = 'https://cdn.getmybubble.com/images/new-quote-flow/bubble-logo.svg';


let maxY;

const baseUrl = process.env.GATSBY_AGENT_PORTAL_URL;
console.log( 'agent portal login url', baseUrl );

const Menu = ( { alt, logo, url } ) => {

  const [showAbout, setAbout] = useState( false );
  const [active, setActive] = useState( 0 );
  const [showNav, toggleNav] = useState( true );
  const [modal, setModal] = useState( false );

  const [showArrow, setArrow] = useState( 0 );

  function clicked( i ){
    if( showArrow === i ){
      setArrow( 0 );
    } else {
      setArrow( i );
    }
  }


  let innerWidth = 992;
  // const { innerWidth } = window;
  if ( typeof window !== 'undefined' ) {
    innerWidth = window.innerWidth;
  }

  const closeMenu = ( e ) => {
    const element = document.getElementById( 'menu-nav' );
    if ( e?.target !== element && !element?.contains( e?.target ) ) {
      document.body.removeEventListener( 'click', closeMenu );
      toggleNav( false );
      setActive( 0 );
    }
  };

  const showModal = ( e ) => {
    const modalBody = document.querySelector( '.modal-body' );

    if( !( e?.target === modalBody || !e?.target.contains( modalBody ) ) ){
      setModal( false );
    }
  };

  useEffect( () => {
    if( active ) {
      document.body.addEventListener( 'click', closeMenu );
    }
  }, [active] );

  useEffect( () => {
    if( showNav &&  innerWidth < 991 ){
      document.body.addEventListener( 'click', closeMenu );
      setTimeout( () => {
        document.getElementById( 'navbarItems' )?.classList.remove( 'hide-sm' );
      }, 0 );
    }
  }, [showNav] );

  useEffect( () => {
    if ( innerWidth < 991 ) {
      toggleNav( false );
      document.body.addEventListener( 'click', closeMenu );
    }
    console.log( 'desktop menu' );
  }, [innerWidth] );


  function closeThis(){
    const closedIn = document.getElementById( 'closed-in' );

    if( closedIn ){
      document.getElementById( 'closed-in' ).style.display = 'none';
    }
  }

  function openThis(){
    const closedIn = document.getElementById( 'closed-in' );

    if( closedIn ){
      document.getElementById( 'closed-in' ).style.display = 'block';
    }
  }

  const [opened, accordionValue] = useState( 0 );

  function closeAccordion(){
    let accordion = ( opened + 1 ) % 3;
    accordionValue( accordion );
  }

  return (
    <nav id="menu-nav" className='d-none d-sm-none d-md-none d-lg-block d-xl-block'>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
          <nav className={`navbar navbar-expand-md fixed-top bgFFF ${showAbout ? '' : 'border-btm-paleGray'}`}>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" style={{ color: 'black' }}>
              </span>
            </button>

            <div id="navbarSupportedContent" hidden/>

            <div className="menuNav menuNavUse" id="navbarCollapse">
              <div className={commonStyles.hdContainer + ' navbar-nav hide-sm '
            + `${showNav ? 'hidden' : 'no-border'}`}>
                {
                  logo ?
                    <>
                      <Link to="/">
                        <img style={{ marginLeft: '12px', marginBottom: '5px' }} src="https://cdn.getmybubble.com/images/new-quote-flow/bubble-logo.svg" alt="Bubble Logo"/>
                      </Link>

                      <a style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 0,
                        justifyContent: 'center',
                        padding: '0px 0 0 20px'
                      }}
                      href={url} target="_blank" rel="noreferrer">
                        <img src={logo} style={{
                          width: '45%',
                          maxWidth: '250px',
                          height: 'auto',
                          marginBottom: 0
                        }} id="logoBubble" alt={alt}/>
                      </a>
                    </>
                    :
                    <Link
                      to="/">
                      <img src={HDImg} className={commonStyles.HDImg} id="logoBubble" alt="Bubble Life Insurance"/>
                    </Link>
                }</div>

              <div  id="navbarItems" className={`navbar-nav navHeight ${showNav ? 'hide-sm' : 'hidden'}`}>
                <div className="navBox rel-position">
                  <div onMouseEnter={() => clicked( 1 )} onMouseLeave={() => clicked( 1 )} className={ showArrow == 1 ? 'testingwrap activeItem' : 'testingwrap'} style={{ padding: '15px 0px' }}>
                    <a className="hlink" tabIndex={0} role="button">About
                      <img className="show-sm show-all m-0"
                        src={showArrow == 1 ?  iconorange : iconback}
                        tabIndex={0} alt="button" role="button" />
                    </a>

                    <div className="testing rel-position">
                      <AboutContent />
                    </div>
                  </div>

                  <div onMouseEnter={() => {
                    clicked( 2 );
                    openThis();
                  }}
                  onMouseLeave={() => {
                    clicked( 2 );
                    closeThis();
                  }} className={ showArrow == 2 ? 'testingwrap activeItem ins-partners' : 'testingwrap ins-partners'} style={{ padding: '15px 0px' }}>
                    <a className="hlink" tabIndex={0} role="button">Insurance
                      <img className="show-sm show-all m-0" src={showArrow == 2 ? iconorange : iconback} alt="button"
                        tabIndex={0} role="button" />
                    </a>

                    <div id="closed-in" onMouseLeave={() => closeAccordion()} key={opened} className="testing rel-position">
                      <InsurancePartners/>
                    </div>
                  </div>

                  <div onMouseEnter={() => clicked( 3 )} onMouseLeave={() => clicked( 3 )} className={ showArrow == 3 ? 'testingwrap activeItem' : 'testingwrap'} style={{ padding: '15px 0px' }}>
                    <a className="hlink" tabIndex={0} role="button">Tools
                      <img className="hlink show-sm show-all m-0" src={showArrow == 3 ? iconorange : iconback} alt="button"
                        tabIndex={0} role="button" />
                    </a>

                    <div className="testing rel-position">
                      <InsuranceCards />
                    </div>
                  </div>

                  <div onMouseEnter={() => clicked( 4 )} onMouseLeave={() => clicked( 4 )} className={ showArrow == 4 ? 'testingwrap activeItem' : 'testingwrap'} style={{ padding: '15px 0px' }}>
                    <a className="hlink"
                      href='/partnerwithus/'
                      tabIndex={0} role="button">Partner With Us
                      <img className="hlink show-sm show-all m-0" src={showArrow == 4 ? iconorange : iconback} alt="button"
                        tabIndex={0} role="button" />
                    </a>

                    <div className="testing rel-position">
                      <div className="dropdown-menutest">
                        <ul className="droplist loginui">

                          <li className="partner-with-us">
                            <a className="hlink" href={'/partnerwithus/'} tabIndex={0} role="button">Partner With Us</a>
                          </li>

                          <li>
                            <div className="loginbtn">
                              <a href={baseUrl + 'login'}><img src={loginbtn} alt="login btn"/>Partner Login</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* <div style={{ padding: '15px 0px' }} className={`${active === 4 ? 'orangeBtnActive testingwrap activeItem' : 'testingwrap activeItem'}`} >
                    <Link to="/partnerwithus"
                    // onMouseOver={( e ) => {
                    //   e.preventDefault();
                    //   active === 4 ? setActive( 0 ) : setActive( 4 );
                    //   window.location.href = 'https://partnerwithus.getmybubble.com'; }}
                    // onMouseOut={( e ) => {
                    //   e.preventDefault();
                    //   active === 4 ? setActive( 0 ) : setActive( 4 );
                    //   window.location.href = 'https://partnerwithus.getmybubble.com'; }}
                      title='Partner With Us'
                      tabIndex={0} role="button">Partner With Us
                    </Link>

                    <div className="show-sm"></div>
                  </div> */}
                </div>

                <div className="hide-sm dropdown1 d-md-none1111 section30">
                  <div className="menu-with-arrow">
                    <div className="sh-brw d-inherit" style={{ width: '72px' }}>
                      <img src={phone} className="phone-img-align dropbtn" alt=""/>
                    </div>

                    <div className="sh-mob" data-bs-toggle="collapse" data-href="#collapseExample99" role="button" aria-expanded="false"             aria-controls="collapseExample">
                      <div className="sh-mob1">Contact Us
                        <img src={droparrow} alt="arrow"/>
                      </div>

                    </div>
                  </div>

                  <div className="dropdown-content aboutPage contact-tooltip">
                    <div className=" flexRow justify-content-between makeColumn">
                      <div id="collapseExample99" >
                        <div className="flexColumn touch">
                          <div className="title">
                            Get in touch with us
                          </div>

                          <div className="flexRow">
                            <img src={call1} alt="call" />

                            <div className="makeColumn">
                              <div className="learnMore fwNormal MobileLftPadding">
                                <a
                                  style={{ color: '#ff7007' }}
                                  className='learnMore'
                                  href="tel:+18339002822">
                                  (833) 900-BUBB
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="flexRow">
                            <img src={email1} alt="email" />

                            <div className="makeColumn">
                              <a className="MobileLftPadding" href="mailto:hello@getmybubble.com" target="_blank" rel="noopener noreferrer">
                                <div className="learnMore fwNormal"> hello@getmybubble.com </div>
                              </a>

                              <p className="para" style={{ opacity: 1 }}> (24/7, at your service)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section10 d-md-none1111" id="contactUs"
                  style={{ pointerEvents: 'all', cursor: 'pointer' }} onClick={() => setModal( true )} data-bs-toggle="modal" data-bs-target="#hubSpotFormMenu">
                  <div > <div className="d-inherit" style={{ width: '72px' }}>
                    <img src={email} className="phone-img-align" alt="phone"/> </div>

                  <div className="sh-mob">Form  </div>
                  </div>
                </div>

                {
                  modal &&
                <div
                  onClick={showModal}
                  className={`modal fade  ${ modal ? 'show' : ''}`}
                  id="hubSpotFormMenu"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-modal="true"
                  role="dialog"
                  style={{ display: 'block', paddingLeft: '0' }}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=> setModal( false )}>
                        </button>
                      </div>

                      <div className="modal-body">
                        <HubSpotContactUsForm closeModal={() => setModal( false ) }/>
                      </div>
                    </div>
                  </div>
                </div>
                }

                {/*
                <div id="formParent">

                  { modal &&
                  <div className="modal fade" id="hubSpotFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                          </button>
                        </div>

                        <div className="modal-body">
                          {/* Contact Modal

                          <HubSpotContactUsForm />
                        </div>
                      </div>
                    </div>
                  </div>

                </div> */}


                {/* <button type="button" className={ 'btn-quote ' + commonStyles.hdLoginBttn}> </button> */}

                {/* <div className="loginbtn">
                  <a href={process.env.GATSBY_AGENT_PORTAL_URL + 'login'}><img src={loginbtn} alt="login btn"/>Partner Login</a>
                </div> */}
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className={commonStyles.hdMianContainermob}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <nav className="navbar navbar-expand-md  fixed-top">
                <a className="navbar-brand" href="/">
                  <img src={HDImg} className={commonStyles.HDImg} style={{ minHeight: '20px', minWidth: '80px' }} alt="logo"/>
                </a>

                <button onClick={() => toggleNav( !showNav )} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon">
                  </span>

                  {!showNav ?
                    <svg width="17" height="12" xmlns="http://www.w3.org/2000/svg">
                      <line x1="1" x2="16" y1="2" y2="2" stroke="#2a2727" strokeWidth="2" />

                      <line x1="1" x2="16" y1="6" y2="6" stroke="#2a2727" strokeWidth="2" />

                      <line x1="1" x2="16" y1="10" y2="10" stroke="#2a2727" strokeWidth="2" />
                    </svg>
                    :
                    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
                      <line x1="1" x2="14" y1="1" y2="14" stroke="#2a2727" strokeWidth="2" />

                      <line x1="14" x2="1" y1="1" y2="14" stroke="#2a2727" strokeWidth="2" />
                    </svg>
                  }
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="hide-sm" onMouseLeave={e => {
          e.persist(); maxY = Math.max( e.nativeEvent.pageY, maxY || 0 );
        }}>
        </div>
      </div>
    </nav>
  );
};


export default Menu;


